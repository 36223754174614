.station-builder {
	.panel {
		width: 100vw;
		display: flex;
		flex-direction: row;
		button {
			width: 100%;
			height: 30px;
			line-height: 30px;
			display: block;
			cursor: pointer;
			text-align: center;
			font-size: 14px;
			font-weight: bold;
			border: none;
			padding: 0;
			&.save {
				flex: 3;
				background: #55aa22;
				color: #FFFFFF;
			}
			&.cancel {
				flex: 3;
				background: #aa0000;
				color: #FFFFFF;
			}
			&.gravity {
				flex: 1;
				background: #0c5069;
				color: #FFFFFF;
			}
		}
	}
	.main {
		display: flex;
		flex-direction: row;
		.left {
			width: 70vw;
			height: calc(100vh - 30px);
			display: block;
		}
		.right {
			width: 30vw;
			height: calc(100vh - 30px);
			display: flex;
			flex-direction: column;
			.panel {
				width: 100%;
				height: 33px;
				display: flex;
				flex-direction: row;
				align-items: center;
				.calculator {
					padding: 5px;
					input {
						&[type=text] {
							border: none;
							border-bottom: solid 1px #000000;
							outline: none;
							width: 50px;
							text-align: center;
						}
					}
				}
				.img {
					padding: 5px;
				}
			}
			.map {
				width: 100%;
				height: calc(100vh - 30px - 33px);
			}
		}
	}
	.dialog-bg {
		top: 0;
		left: 0;
		position: fixed;
		width: 100vw;
		height: 100vh;
		display: block;
		background: #000000;
		opacity: 0.3;
		z-index: 100;
	}
}
