.stop-dialog {
    position: fixed;
    top: 200px;
    left: calc(100vw / 2 - 200px);
    width: 400px;
    height: 260px;
    display: flex;
    flex-direction: column;
    background: #55aa55;
    border: solid 1px #296b29;
    border-radius: 5px;
	padding: 10px;
	z-index: 101;
	&.extended {
		top: calc(100vh / 2 - 200px);
		left: calc(100vw / 2 - 300px);
		width: 600px;
		height: 400px;
	}
    .header {
        height: 25px;
        border-bottom: solid 1px #555;
    }
    .content {
        flex: auto;
		padding-top: 5px;
		display: flex;
		flex-direction: row;
		.left {
			width: initial;
			height: initial;
		}
		.right {
			padding-left: 20px;
			width: initial;
			height: initial;
			table {
				border-top: solid 1px #555555;
				border-left: solid 1px #555555;
				margin: 0;
				padding: 0;
				tbody {
					margin: 0;
					padding: 0;
					tr {
						margin: 0;
						padding: 0;
						&.header {
							td {
								font-weight: bold;
							}
						}
						td {
							border-bottom: solid 1px #555555;
							border-right: solid 1px #555555;
							margin: 0;
							padding: 3px;
						}
					}
				}
			}
		}
        div {
            padding: 5px 0 0 0;
        }
    }
    .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
}
