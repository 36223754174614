body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gtfs-stations-builder-container {
  width: 100vw;
  height: 100vh;
  .loader {
	position: fixed;
	width: 100%;
	height: 100%;
	display: block;
	background: rgba(200, 200, 255, 0.7) url("./images/loading.svg") no-repeat center center;
	z-index: 100;
	top: 0;
	left: 0;
  }
  .controls {
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	img {
		padding: 0 0 20px 0;
	}
  }
  .map-header {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
  }
  .map {
    width: 100vw;
    height: calc(100vh - 30px);
    display: block;
    &.hidden {
      visibility: hidden;
      height: 0;
    }
    .station-name {
      position: absolute;
      font-family: Verdana;
      font-size: 10px;
      padding: 4px 0px 0px;
      text-shadow: 0px 0px 4px #0000ff;
    }
  }
  .panel {
    width: 100vw;
    display: flex;
    flex-direction: row;
    button {
      flex: 1;
      width: 100%;
      height: 30px;
      line-height: 30px;
      display: block;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      border: none;
      padding: 0;
      &.load {
        background: #aaaaa0;
        color: #ffffff;
      }
      &.download {
        background: #0000dd;
        color: #ffffff;
      }
    }
  }
}
